import { httpClient } from '@/app/shared/http-service';
import { apiGlobalConstants } from '../config';

// URL base, completar con modelos con comentarios disponibles
const URL = apiGlobalConstants.admin;
const URL_COMENTARIOS = apiGlobalConstants.comentario;

// {{BASE_URL_ADMIN}}/comentarios?page=1&element=App\Models\Base\Empresas&element_id=200
const fetchAllComentarios = (modelo, elementID, type='empresas', page = null, perPage = null) => {
  const filter = `element=${modelo}&element_id=${elementID}`;

  if (page != null) {
    return httpClient.get(`${URL_COMENTARIOS}/${type}/comentar?page=${page}&per_page=${perPage}&${filter}`).then(res => res.data)
  }
  else {
    return httpClient.get(`${URL_COMENTARIOS}?${filter}`).then(res => res.data)
  }
};

// crear comentario por modelo, por ahora comentarios a empresas
// {{BASE_URL_ADMIN}}/empresas/comentar/200
const createComentario = (data, type) => {
  return httpClient.post(`${URL}/${type}/comentar/${data.id}`, {'mensaje': data.mensaje}).then(res => res.data);
};

const fetchOneComentario = (comentarioId) => {
  return httpClient.get(`${URL_COMENTARIOS}/${comentarioId}`).then(res => res.data);
};

const updateComentario = (commentarioToEdit) => {
  return httpClient.put(`${URL_COMENTARIOS}/${commentarioToEdit.id}`, commentarioToEdit).then(res => res.data);
};

const deleteComentario = (comentarioId) => {
  return httpClient.delete(`${URL_COMENTARIOS}/${comentarioId}`).then(res => res.data);
};

export {
  fetchAllComentarios,
  fetchOneComentario,
  updateComentario,
  deleteComentario,
  createComentario,
}
