import { httpClient, setResponseType } from '@/app/shared/http-service';
import { apiGlobalConstants } from '../config';

const URL = apiGlobalConstants.matricula;

export const fetchAllMatriculas = (page = null, filtro = '', perPage = null) => {
  let filter = filtro.length > 0 ? filtro : "";

  if (page != null) {
    return httpClient.get(`${URL}?page=${page}&per_page=${perPage}&${filter}`).then(res => res.data)
  }
  else {
    return httpClient.get(`${URL}?${filter}`).then(res => res.data)
  }
};

export const fetchOneMatricula = (matriculaId) => {
  return httpClient.get(`${URL}/${matriculaId}`).then(res => res.data);
};

export const updateEstados = (matriculaToEdit) => {
  return httpClient.put(`${URL}/actualizar-estados/${matriculaToEdit.id}`, matriculaToEdit).then(res => res.data);
};

export const updateProcesoSeleccion = (matriculaToEdit) => {
  return httpClient.put(`${URL}/actualizar-proceso-seleccion/${matriculaToEdit.id}`, matriculaToEdit).then(res => res.data);
}

export const createMatricula = (data) => {
  return httpClient.post(`${URL}`, data).then(res => res.data);
};

export const deleteMatricula = (matriculaId) => {
  return httpClient.delete(`${URL}/${matriculaId}`).then(res => res);
};

export const asociarConvocatoria = (matriculaId, convocatoriaId) => {
  return httpClient.put(`${URL}/anadir-convocatoria/${matriculaId}`, {convocatoria: convocatoriaId}).then(res => res);
}

export const getConvocatorias = (matriculaId) => {
  return httpClient.get(`${URL}/convocatorias/${matriculaId}`).then(res => res.data);
}

export const desasociarConvocatoria = (matriculaId, convocatoriaId) => {
  return httpClient.put(`${URL}/quitar-convocatoria/${matriculaId}`, {convocatoria: convocatoriaId}).then(res => res);
}

export const updateConvocatoria = (matriculaId, convocatoriaToEdit) => {
  return httpClient.put(`${URL}/actualizar-convocatoria/${matriculaId}`, {...convocatoriaToEdit, convocatoria: convocatoriaToEdit.convocatoria_id}).then(res => res);
}

export const recordarConvocatoria = (matriculaId, convocatoriaId) => {
  return httpClient.put(`${URL}/recordar-convocatoria/${matriculaId}`, {convocatoria: convocatoriaId}).then(res => res);
}

export const cambiarGrupo = (matriculaId, grupo_id) => {
  return httpClient.put(`${URL}/cambiar-grupo/${matriculaId}`, {grupo_id}).then(res => res.data);
}

export const cambiarEstadoMatriculado = (matriculaId) => {
  return httpClient.put(`${URL}/matricular/${matriculaId}`, {}).then(res => res.data);
}

// api/admin/matriculas/anadir-expediente/{id}
export const addNumExpediente = (matriculaId, numero_expediente) => {
  return httpClient.put(`${URL}/anadir-expediente/${matriculaId}`, {numero_expediente}).then(res => res.data);
}

export const updateAreas = (tipo, matriculaId, areas_preferencia) => {
  return httpClient.put(`${URL}/actualizar-areas-preferencia/${tipo}/${matriculaId}`, {areas_preferencia}).then(res => res.data);
}

export const updateDocumentacion = (matriculaId, data) => {
  return httpClient.put(`${URL}/documentacion/${matriculaId}`, data).then(res => res.data);
}

export const exportarExcel = (matriculaId) => {
  setResponseType('blob')
  return httpClient.get(`${URL}/exportar-para-contrato/${matriculaId}`).then(res => {
    setResponseType('json');

    return res;
  });
}
