import axios from 'axios';
import router from '../../router';
import { apiBaseUrl } from "@/environment/environment";

const config = {
  baseUrl: apiBaseUrl,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
};

const httpClient = axios.create(config);

export function setHeader(header) {
  httpClient.defaults.headers.common[header.key] = header.value;
}

export function setResponseType(reponseType) {
  httpClient.defaults.responseType = reponseType || 'json';
}

export function removeResponseType() {
  delete httpClient.defaults.responseType;
}

const authInterceptor = config => {
  const token = localStorage.getItem("token");

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  } else {
    delete config.headers['Authorization'];
  }

  return config;
}

const loggerInterceptor = config => {
  /** Add loggin here */
  return config;
};

httpClient.interceptors.request.use(authInterceptor);
httpClient.interceptors.request.use(loggerInterceptor);


httpClient.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    /** Do somethig with error response */

    // return Promise.reject(error); 

    // console.error(error?.response?.status,'interceptor');

    if (error?.response?.status) {
      switch (error.response.status) {
        case 419:
          //TODO
          //do something
          break;
        case 400:
          //TODO
          //do something
          break;

        case 401:
          console.error('La sesión ha expirado.');
          localStorage.removeItem("token");
          router.replace({ path: "/login" });
          break;

        case 403:
          console.error('No está autorizado.');
          // router.replace({
          //   path: "/login",
          //   query: { redirect: router.currentRoute.fullPath }
          // });
           break;

        case 404:
          // TODO: Page not found
          // console.error('La página no existe.');
          // alert('page not exist');
          break;

        case 502:
          // TODO
          setTimeout(() => {
            // router.replace({
            //   path: "/login",
            //   query: {
            //     redirect: router.currentRoute.fullPath
            //   }
            // });
          }, 1000);
      } 
    // console.error(Promise.reject(error.response),'interceptor');

      return Promise.reject(error);
    }

  }
);

export { httpClient };

