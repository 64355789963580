import { h, resolveComponent } from 'vue'

const ListadoCentros = () => import('@/app/views/centros/views/ListadoCentros');
const DetalleCentro = () => import('@/app/views/centros/views/DetalleCentro');
const NuevoCentro = () => import('@/app/views/centros/views/NuevoCentro');

const centrosRoutes = [
  {
    path: 'centros',
    name: 'Centros',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      }
    },
    children: [
      {
        path: '',
        name: 'centro:Listado',
        component: ListadoCentros,
        meta: {
          auth: true,
          entidad: 'centros'
        }
      },
      {
        path: 'nuevo',
        name: 'centro:Nuevo',
        component: NuevoCentro,
        meta: {
          auth: true,
          entidad: 'centros'
        },
      },
      {
        path: ':id',
        name: 'centro:Detalle',
        component: DetalleCentro,
        meta: {
          auth: true,
          entidad: 'centros'
        },
      }
    ]
  }
];

export default centrosRoutes;
