
export const aulaVitual = {
  state: {
    tests: []
  },
  mutations: {
    addTest(state, payload) {
      // state.tests[payload.name] = payload.datos
      state.tests[state.tests.length] = payload.datos;

      // eliminar duplicados
      // state.tests = [...new Set(state.tests)];
    },
    removeTest(state, payload) {
      state.tests.splice(payload.index,1);
    },
    editTest(state, payload) {
      state.tests[payload.index] = payload.datos
    },
    removeTest(state, payload) {
      state.tests[payload.index] = payload.datos
    },
    setAllTests(state, tests) {
      state.tests = [...tests]
    },

  },
  actions: {
    addTest(context, payload) {
      context.commit('addTest', payload)
    },
    removeTest(context, payload) {
      context.commit('addTest', payload)
    },
    editTest(context, payload) {
      context.commit('editTest', payload)
    },
    setAllTests(context, payload) {
      context.commit('setAllTests', tests)
    },

  }
}