<template>
  <div class="animated fadeIn">
    <CModal v-model:visible="showModal" size="lg" @close="() => $emit('cancelled',false)">
      <CModalBody class="text-center d-block p-0 m-0">
        <spinner-ttt :show="!url"/>
        <div class="files-container-preview" :class="{'showfake': !url}">
            <img ref="imagePreview" :src="url" alt=""  class="w-100"/>
              <!--  style="height: 337px; object-fit: cover; object-position: 100% 0;" -->
        </div>
      </CModalBody>
    </CModal>
  </div>
</template>

<script>

export default {
  name: 'VerImagenModal',
  props: {
    //alumno: { type: String, required: true },
    blobUrl: {type: String, required: true},
    show: { type: Boolean, default: false, required: true },
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    loadedBlob(){
      if (this.url) URL.revokeObjectURL(this.url);
    },
  },
  computed: {
    showModal() {
      return this.show;
    },
    url: {
      get: function () {
        return this.blobUrl
      },
      set: function (newValue) {
        this.$emit("update:blobUrl", newValue);
      }
    },
  },
}
</script>
