<template>
    <CButton @click="goTo()" class="button-new d-flex align-items-center" color="primary">
        <CIcon v-if="iconBtn" name="cil-arrow-left" size="xl" class="clickeable mr-4" />
        <span :class="{'mx-2': iconBtn}">{{ tituloBtn }}</span>
    </CButton>
</template>

<script>
import { paginaAnterior } from '@/app/shared/utils/tools';

export default {
    name: 'ButtonVolverRouter',
    props: {
        tituloBtn: { type: String, default: 'Volver'},
        iconBtn: { type: Boolean, default: true},
        ruta: { type: String || Boolean, default: false }
    },
    methods: {
        goTo() {
            if (this.ruta) {
                this.$router.push({path: this.ruta});
                return
            }
            paginaAnterior();
        }
    }
}
</script>