<template>
  <div class="animated fadeIn">
    <CModal v-model:visible="showModal" size="lg" @close="() => $emit('cancelled',false)">

      <CModalHeader class="bg-primary text-light">
        <CModalTitle>Nuevo comentario</CModalTitle>
      </CModalHeader>

      <CModalBody class="p-0 d-block">
        <QuillEditor
            id="nuevoComentario"
            name="nuevoComentario"
            ref="quillEditor"
            v-model:content="nuevoComentario.texto"
            contentType="html"
            :options="editorOption"
            class="quill-textarea custom-quill"
            />
      </CModalBody>

       <CModalFooter>
        <CButton @click="$emit('cancelled',false)" color="link" class="text-secondary">Cancelar</CButton>
        <CButton :disabled="!hayTexto()" class="text-light" @click="comentar" color="primary">
          <!-- <CIcon name="cil-check-circle" />  -->
          Comentar
        </CButton>
       </CModalFooter>

    </CModal>
  </div>
</template>

<script>
import { QUILL_CONFIG_COMENTARIO } from "@/config";

export default {
  name: 'NuevoComentarioModal',
  props: {
    show: { type: Boolean, default: false, required: true },
  },
  updated() {
    console.log(this.$refs);
  },
  computed: {
    showModal() {
      return this.show;
    },
    quill() {
      return this.mounted ? this.$refs.length > 0 ? this.$refs.quillEditor.content : '' : ''
    }
  },
  data() {
    return {
      editorOption: QUILL_CONFIG_COMENTARIO,
      nuevoComentario:{
        texto: "",
      },
      mounted: false,
    }
  },
  methods: {
    comentar() {
      this.$emit('comentar', {...this.nuevoComentario});

      console.log(this.$refs.quillEditor);
      this.$refs.quillEditor.setText("");
    },
    hayTexto() {
      return this.nuevoComentario.texto.replaceAll('<p>', '')
      .replaceAll('</p>', '')
      .replaceAll('<br>', '');
    }
  }
}
</script>

<style>

</style>
