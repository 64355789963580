import { h, resolveComponent } from 'vue'

const ListadoMedicos = () => import('@/app/views/medicos/views/ListadoMedicos');
const DetalleMedico = () => import('@/app/views/medicos/views/DetalleMedico');
const NuevoMedico = () => import('@/app/views/medicos/views/NuevoMedico');


const medicosRoutes = [
  {
    path: 'medicos',
    name: 'Medicos',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      }
    },
    children: [
      {
        path: '',
        name: 'medicos:Listado',
        component: ListadoMedicos,
        meta: {
          auth: true,
          entidad: 'medicos'
        }
      },
      {
        path: 'nuevo',
        name: 'medicos:Nuevo',
        component: NuevoMedico,
        meta: {
          auth: true,
          entidad: 'medicos'
        },
      },
      {
        path: ':id',
        name: 'medicos:Detalle',
        component: DetalleMedico,
        meta: {
          auth: true,
          entidad: 'medicos'
        },
      }
    ]
  }
];

export default medicosRoutes;
