<template>
<div>

  <CRow class="mt-2">
    <CCol sm="10" class="px-4">
      <div class="d-flex justify-content-start align-items-center">
        <div class="d-flex justify-content-start align-items-start"> 
          <CIcon name="cil-user" size="lg" class="me-1" style="font-size: 2.25rem;" />
          <span class="mt-0">
            <span class="d-block"><strong class="float-start d-flex align-items-center m-0 p-0">
              {{ comentario.usuario ? comentario.usuario : 'Anónimo' }}</strong></span>
            <small style="font-size: 0.775em" class="float-start">{{ getDateFormat(comentario.fecha) }}</small>
          </span>

        </div>
      </div>
    </CCol>

    <CCol sm="2">
       <!-- <span class="mx-2">{{comentario.id}}</span> -->
      <div v-if="isAuthor" class="dropdown pt-1 d-flex justify-content-end align-items-start">
        <CIcon
          class="dropdown-toggle"
          size="lg"
          name="cil-options"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        />
        <!-- @click="$emit('editar', comentario)" -->
        <ul  class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li @click="editar()">
            <span class="dropdown-item"
              ><CIcon
                size="lg"
                name="cil-pencil"
                class="me-2"
              />Editar</span
            >
          </li>

          <li @click="$emit('eliminar', comentario)">
            <span class="dropdown-item">
              <CIcon
                size="lg"
                name="cil-trash"
                class="me-2 text-danger"
              />Eliminar</span>
          </li>
        </ul>
      </div> 
    </CCol>
  </CRow>
  <CRow v-if="comentario && !editarComentario">
    <CCol sm="12" class="px-4 pt-1 pb-0 box" ref="box" :class="{'open': isOpen}">
      <div class="text comentario-text mb-0" v-html="comentario.mensaje"></div>
      <div class="mt-1"><a v-if="mostrarLink" class="comentario-link pointer" @click="readMoreBtn">Mostrar {{ isOpen ? 'menos' : 'más'}}</a></div>
    </CCol> 
  </CRow>

  <CRow v-if="comentario && editarComentario && commentToEdit.mensaje">
      <CCol sm="12" class="px-4 pt-1 pb-0">
        <QuillEditor
          id="editarComentario"
          name="editarComentario"
          className="comentario-editar"
          ref="quillEditor"
          v-model:content="commentToEdit.mensaje"
          contentType="html"
          :options="editorOption"
          class="quill-textarea custom-quill"
          @blur="($event) => focusEdit = false" 
          @focus="($event) => focusEdit = true" 
          />

      </CCol>

      <CCol sm="12" class="px-4 pt-1 pb-2">
        <div class="d-flex justify-content-start mt-3">

          <CButton size="sm" 
            :color="focusEdit ? 'primary' : 'link'" 
            :class="{'text-secondary':  focusEdit, 'text-secondary' : !focusEdit }"
            @click="editComentario()">
            Guardar
          </CButton>
          
          <CButton 
            size="sm" 
            @click="cancelar()" 
            color="link" 
            class="text-secondary">
            Cancelar
          </CButton>
          
        </div>
      </CCol>  
  </CRow>



  <toast ref="toast"></toast>
</div>
</template>

<script>

import { dateFormatTimeAgo } from '@/app/shared/utils/tools';
import { QUILL_CONFIG_COMENTARIO, ADMINSTRADORES_MODEL } from "@/config";

export default {
  name: 'CardComentarioItem',
  props: {
    loading: { type: Boolean, default: false, required: true },
    item: { type: Object, default() { return {} }, required: true },
  },
  data() {
    return {
      comentariosCardCollapsed: true,
      comentariosCollapsed: true,
      nuevoModal: false,
      isOpen: false,
      editarComentario: false,
      editorOption: QUILL_CONFIG_COMENTARIO,
      commentToEdit: { "mensaje" : null},
      focusEdit: false
    }
  },
  computed: {
    comentario: {
      get: function () { return {...this.item} },
      set: function (newValue) { this.$emit("update:item", newValue) },
    },
    isAuthor() { 
      if (this.comentario) {
        return this.comentario.tipo_usuario === ADMINSTRADORES_MODEL.path &&
        localStorage.getItem("user_id") == this.comentario.usuario_id
      } 
      return false
    },
    loadingItem: {
      get: function () {
        return this.loading
      },
      set: function (newValue) {
        this.$emit('update:loading', newValue)
      },
    },

    mostrarLink() {
      if (this.comentario && this.comentario.mensaje) {
       const text = this.comentario.mensaje.replaceAll('<p>', '')
        .replaceAll('</p>', '')
        .replaceAll('<br>', '');

        return text.trim().length > 167; // 175
      }
    },

    isOverflowing() {
      this.$nextTick(() => {
      let element = this.$refs.commentDiv;
      if (!element) return
      return (element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth)
      });
    },
  },

  methods: {
    readMoreBtn() {
      this.isOpen = !this.isOpen;
    },
    toggleComentarios() {
      this.comentariosCollapsed = !this.comentariosCollapsed
    },
    getDateFormat(fecha) {
      if (!fecha) return
      return dateFormatTimeAgo(fecha)
      // momentjs is deprecated
      // return moment(fecha).fromNow()
    },

    cancelar() {
      this.editarComentario = false;
      this.commentToEdit = {"mensaje": null};
      this.$refs.quillEditor.setText("");
    },
    editar() {
      if (!this.isAuthor) {
        this.$refs.toast.showToast(`Acción no permitida`, null, 'warn', '5000');
        return
      }

      this.editarComentario = true;
      this.commentToEdit = {...this.comentario};
    },
    editComentario() {
      this.$emit('editar', this.commentToEdit);
      this.cancelar();
    },

    isOverflowY() {
      let element = this.$refs.commentDiv;
      return element.scrollHeight != Math.max(element.offsetHeight, element.clientHeight)
    }
  },
}
</script>

<style lang="scss" scoped>

.comentario {
  &-text {
    font-size: 0.9rem;
    p {
      line-height: 1.2;
    }
  }

  &-link {
    font-size: 0.8rem;
  }

  // &-editar {
  //   border: 1px solid var(--cui-btn-bg) !important;
  // }
}

/////////////// mostrar más/menos texto
/* Box */
.box {
  width: 100%;
  max-height: 162px;
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
}

.box.open {
  max-height: 100rem;
  transition: max-height 0.3s cubic-bezier(0.9, 0, 0.8, 0.2);
}

/* Text */
@keyframes open {
  from {
    line-clamp: 3;
    -webkit-line-clamp: 3;
  }
  to {
    line-clamp: initial;
    -webkit-line-clamp: initial;
  }
}

@keyframes close {
  from {
    line-clamp: initial;
    -webkit-line-clamp: initial;
  }
  to {
    line-clamp: 3;
    -webkit-line-clamp: 3;
  }
}

.text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 12px 0;
  animation: close 0.1s linear 0.1s forwards;
}
.open .text {
  animation: open 0.1s linear 0s forwards;
}

// .ql-container.ql-snow {
//   // border: 1px solid #d1d5db;
//   border: 1px solid var(--cui-btn-bg) !important;
// }

// #editarComentario>:first-child {
//   border: 1px solid var(--cui-btn-bg) !important;
// }



</style>
