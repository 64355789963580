import { h, resolveComponent } from 'vue'

const DetalleTarificacion = () => import('@/app/views/configuracion/tarificacion/views/DetalleTarificacion');


const tarificacionRoutes = [
  {
    path: 'tarificacion',
    name: 'Tarificación',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      }
    },
    children: [
      {
        path: '',
        name: '',
        component: DetalleTarificacion,
        meta: {
          auth: true,
          entidad: 'tarificacion'
        },
      }
    ]
  }
];

export default tarificacionRoutes;
