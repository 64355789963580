import { h, resolveComponent } from 'vue'

const ListadoTipologias = () => import('@/app/views/configuracion/tipologias/views/ListadoTipologias')
const NuevaTipologia = () => import('@/app/views/configuracion/tipologias/views/NuevaTipologia')
const DetalleTipologia = () => import('@/app/views/configuracion/tipologias/views/DetalleTipologia')

const tipologiasRoutes = [
  {
    path: 'tipologias',
    name: 'Tipologías',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      }
    },
    children: [
      {
        path: '',
        name: 'tipologia:Listado',
        component: ListadoTipologias,
        meta: {
          auth: true,
          entidad: 'tipologias'
        }
      },
      {
        path: 'nueva',
        name: 'tipologia:Nueva',
        component: NuevaTipologia,
        meta: {
          auth: true,
          entidad: 'tipologias'
        }
      },
      {
        path: 'detalle/:id',
        name: 'tipologia:Detalle',
        component: DetalleTipologia,
        meta: {
          auth: true,
          entidad: 'tipologias'
        }
      }
    ]
  }
]

export default tipologiasRoutes
