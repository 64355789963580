import { httpClient } from '@/app/shared/http-service';
import { apiGlobalConstants } from '../config';
import $store from '@/store';

const URL = apiGlobalConstants.tipologia
const URL2 = apiGlobalConstants.tipologia2

export const fetchAllTypologies = (page = null, filtro = '') => {
  let filter = filtro.length > 0 ? filtro : ''

  if (page !== null)
    return httpClient.get(`${URL}/obtener?page=${page}&${filter}`).then(r => r.data)
  else
    return httpClient.get(`${URL}/obtener?${filter}`).then(r => r.data)
}
export const fetchAllTypologies2 = (page = null, filtro = '') => {
  let filter = filtro.length > 0 ? filtro : ''

  if (page !== null)
    return httpClient.get(`${URL2}?page=${page}&${filter}`).then(r => r.data)
  else
    return httpClient.get(`${URL2}?${filter}`).then(r => r.data)
}

export const createTypology = (data) => {
  return httpClient.post(`${URL2}`, data).then(res => {
    //  TODO: delete store? en la respuesta del api está la clave para indentificar y eliminar
    return res.data
  });
}

export const fetchTypology = (id) => {
  return httpClient.get(`${URL2}/${id}`).then(res => res.data);
};

export const putTypology = (id, data) => {
  return httpClient.put(`${URL2}/${id}?nombre=${data.nombre}`).then(res => {
    $store.commit('removeStoreTipologia',{ id: id })
    return res.data
  });
}

export const deleteTypology = (id) => {
  return httpClient.delete(`${URL2}/${id}`).then(res => {
    $store.commit('removeStoreTipologia',{ id: id })
    return res.data
  });
}

export const orderTypologySon = (id, data) => {
  return httpClient.put(`${URL2}/ordenar/${id}`, { hijos: data }).then(res => {
    $store.commit('removeStoreTipologia',{ id: id })
    return res.data
  });
}

export const availableRoutes = () => {
  return httpClient.get(`${URL}/rutas-disponibles`).then(r => r.data)
}

export const fetchTypologyWithSons = (slug) => {
  return httpClient.get(`${URL}/obtener/${slug}`).then(res => {
    // console.warn('Get ' + slug)
    $store.commit('addStore', {
      name: slug,
      tipo: 'tipologia',
      // id: res.data.item.id,
      data: {...res.data, tipo: 'tipologia'}
    });

    return  res.data
  });
}
