import { createStore } from 'vuex'
import { coreUI } from './sidebar'
import { cache } from './filtros'
import { permisos } from './permisos'
import { datos } from './datos'
import { aulaVitual } from './aula'
export default createStore({
  modules: {
    coreUI,
    cache,
    permisos,
    datos,
    aulaVitual
  }
})
