<template>
    <div class="animated fadeIn">
      <CModal v-model:visible="showModal" size="lg" @close="cerrarModal">
  
        <CModalHeader class="bg-primary text-light">
          <CModalTitle>Nuevo Paciente</CModalTitle>
        </CModalHeader>
  
        <spinner-ttt :show="loading"></spinner-ttt>
        <CModalBody>
          <Form as="CForm" ref="form" @submit.prevent class="mt-2" v-slot="{ errors }">
            <CRow>
              <!-- <CCol sm="6" class="mb-3">
                    <CFormLabel for="beca_estado">Centro Medico</CFormLabel>
                    <Field
                        as="CFormSelect"
                        id="sexo" name="sexo"
                        v-model="paciente.centro_id"
                        rules="required"
                        :class="{ 'is-invalid': formError(errors, 'sexo')}"
                        v-bind:invalid-feedback="errors.sexo"
                    >
                    <option value="">Selecciona un centro</option>
                        <option v-for="(item) in centros_medicos" :value="item.value">
                            {{ item.label }}
                        </option>
                    </Field>
                    <small class="text-danger ml-2" v-if="formError(errors, 'sexo')">{{ errors.sexo }}</small>
                </CCol> -->
                <CCol sm="6" class="mb-3">
                    <CFormLabel class="required">Nombre</CFormLabel>
                    <Field
                        as="CFormInput"
                        type="text"
                        id="name" name="nombre"
                        :rules="'required'"
                        placeholder="Introduce un nombre"
                        v-model="paciente.nombre"
                        :class="{ 'is-invalid': formError(errors, 'nombre')}"
                        v-bind:invalid-feedback="errors.nombre"
                    />
                    <small class="text-danger ml-2" v-if="formError(errors, 'nombre')">{{ errors.nombre }}</small>
                </CCol>
                <CCol sm="6" class="mb-3">
                    <CFormLabel class="required">Apellidos</CFormLabel>
                    <Field
                        as="CFormInput"
                        type="text"
                        id="apellidos" name="apellidos"
                        :rules="'required'"
                        placeholder="Introduce los apellidos"
                        v-model="paciente.apellidos"
                        :class="{ 'is-invalid': formError(errors, 'apellidos')}"
                        v-bind:invalid-feedback="errors.nombre"
                    />
                    <small class="text-danger ml-2" v-if="formError(errors, 'apellidos')">{{ errors.apellidos }}</small>
                </CCol>
                </CRow>     
                <CRow>
                <CCol sm="6" class="mb-3">
                    <CFormLabel class="required">Móvil</CFormLabel>
                    <Field
                        as="CFormInput"
                        type="text"
                        id="movil" name="movil"
                        :rules="'required'"
                        placeholder="Introduce un teléfono"
                        v-model="paciente.movil"
                        :class="{ 'is-invalid': formError(errors, 'movil')}"
                        v-bind:invalid-feedback="errors.movil"
                    />
                    <small class="text-danger ml-2" v-if="formError(errors, 'movil')">{{ errors.movil }}</small>
                </CCol>
                <CCol sm="6" class="mb-3">
                    <CFormLabel class="required" for="beca_estado">Sexo</CFormLabel>
                    <Field
                        as="CFormSelect"
                        id="sexo" name="sexo"
                        v-model="paciente.sexo"
                        rules="required"
                        :class="{ 'is-invalid': formError(errors, 'sexo')}"
                        v-bind:invalid-feedback="errors.sexo"
                    >
                    <option value="">Selecciona un sexo</option>
                        <option v-for="(item) in sexos" :value="item.value">
                            {{ item.label }}
                        </option>
                    </Field>
                    <small class="text-danger ml-2" v-if="formError(errors, 'sexo')">{{ errors.sexo }}</small>
                </CCol>
                </CRow>
                <CRow>
                <CCol sm="6" class="mb-3">
                    <CFormLabel class="required" for="beca_estado">Año de nacimiento <strong v-if="paciente.nacimiento">({{ this.getEdad(paciente.nacimiento) }})</strong></CFormLabel>
                    <Field
                        as="CFormSelect"
                        id="nacimiento" name="nacimiento"
                        v-model="paciente.anyo_nacimiento"
                        rules="required"
                        :class="{ 'is-invalid': formError(errors, 'nacimiento')}"
                        v-bind:invalid-feedback="errors.nacimiento"
                    >
                    <option value="">Selecciona un año</option>
                        <option v-for="(item) in years" :value="item.value">
                            {{ item.label }}
                        </option>
                    </Field>
                    <small class="text-danger ml-2" v-if="formError(errors, 'nacimiento')">{{ errors.nacimiento }}</small>
                </CCol>
                <CCol sm="6" class="mb-3">
                    <CFormLabel>Código Postal</CFormLabel>
                    <Field
                        as="CFormInput"
                        type="text"
                        id="codigo_postal" name="codigo_postal"
                        placeholder="Introduce un código postal"
                        v-model="paciente.codigo_postal"
                    />
                </CCol>
                </CRow>
                <CRow>
                <CCol sm="6" class="mb-3">
                    <CFormLabel>DNI</CFormLabel>
                    <Field
                        as="CFormInput"
                        type="text"
                        id="dni" name="dni"
                        placeholder="Introduce un DNI"
                        v-model="paciente.identificacion"
                    />
                </CCol>
                <CCol sm="6" class="mb-3">
                    <CFormLabel class="required">Email</CFormLabel>
                    <Field
                    as="CFormInput"
                    type="text"
                    id="email" name="email"
                    :rules="'required|validEmail'"
                    placeholder="Introduce un email"
                    v-model="paciente.email"
                    :class="{ 'is-invalid': formError(errors, 'email')}"
                    v-bind:invalid-feedback="errors.email"
                    />
                    <small class="text-danger ml-2" v-if="formError(errors, 'email')">{{ errors.email }}</small>
                </CCol>
                </CRow>
  
          </Form>
        </CModalBody>
  
         <CModalFooter>
          <CButton @click="cerrarModal" class="text-secondary" color="link">Cancelar</CButton>
          <CButton class="text-light" @click="crearPaciente" color="primary">
            <!-- <CIcon name="cil-check-circle" />  -->
            Crear
          </CButton>
         </CModalFooter>
  
      </CModal>
    </div>
    <toast ref="toast"></toast>
  </template>
  
  <script>  
  import { PACIENTE_FIELDS } from '@/config';
  import { formError, setStoreData } from '@/app/shared/utils/tools';
import { suggestCentros } from '../../global/services/centros-service';
  
  export default {
    name: 'NuevoPacienteModal',
    props: {
      show: { type: Boolean, default: false, required: true },
      paciente: {type: Object},
      relacion: String
    },
    computed: {
      showModal() {
        return this.show;
      },
      item: {
        get: function () {
          return this.paciente
        },
        set: function (newValue) {
          this.$emit("update:paciente", newValue);
        }
      },
    },
    data() {
      return {
        listadoTipos: [],
        loading: false,
        centros: [
            {
                id: 2,
                nombre: 'Centro 1'
            },
            {
                id: 3,
                nombre: 'Centro 2'
            },
            {
                id: 2,
                nombre: 'Centro 3'
            }
        ],
        sexos: [
            {
                label: 'Hombre',
                value: 'H'
            },
            {
                label: 'Mujer',
                value: 'M'
            }
        ],
        years: []
      }
    },
    mounted() {
        let year = new Date().getFullYear();

        for(let i = year; i >= year-100; i--) {
            this.years.push({
                label: i,
                value: i
            });
        }

        // suggestCentros("").then(res => { 
        //   res.data.forEach(centro => {
        //       this.centros_medicos.push({
        //         nombre: centro.nombre,
        //         id: centro.id
        //       })
        //   });
        // }).catch(e => {
        //   console.log(e);
        //   this.$refs.toast.showToast("Error obteniendo los centros", null, "error", "5000");
        // })
    },
    methods: {
      crearPaciente() {
        this.$refs.form.validate().then(v => {
          if(!v.valid) return
  
          this.loading = true;
          this.$emit('crearPaciente');
        })
      },
  
      formError(errors, value) {
        return formError(errors, value);
      },

      getEdad(year) {
        return new Date().getFullYear() - year;
      },
  
      cerrarModal() {
        this.loading = false;
        this.item = {...PACIENTE_FIELDS};
        this.$emit('cancelled',false);
      }
    }
  }
  </script>
  
  <style>
  
  </style>
  