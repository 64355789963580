<template>
  <div class="d-flex align-items-center me-2 justify-content-between">
    <CNavLink class="clickeable" :href="`/#/configuracion/usuarios/yo`">
      <CIcon size="lg" name="cil-user" class="me-2"/>
      <span class="d-none d-sm-inline">{{ userName }}</span>
  </CNavLink>
  <div @click="logout" class="ms-2 mt-1">
    <CIcon size="lg" name="cil-account-logout" class="clickeable" />
  </div>

  <toast ref="toast"></toast>
  </div>
</template>

<script>
export default {
  name: 'AppHeaderAccnt',
  data () {
    return {
      itemsCount: 42,
      userName: localStorage.getItem("user_name") ?? 'Usuario',
      user_id: localStorage.getItem("user_id")
    }
  },
  methods: {
    logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('user_name');
      localStorage.removeItem('user_id');
      localStorage.removeItem('user_email');
      localStorage.removeItem('user_rol');
      localStorage.removeItem('user_ayuntamiento_id');
      localStorage.removeItem('login_time');
      localStorage.removeItem('expires_in');
      this.$refs.toast.showToast( `Deslogueado correctamente.`, null, "success", "5000");
      this.$router.push('/login');
    },
  }
}
</script>

<style lang="scss" scoped>
  .c-icon {
    margin-right: 0.3rem;

    &:hover {
      cursor: pointer;
    }
  }

  // .cil-account-logout:hover {
  // }
</style>
