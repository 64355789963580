import { httpClient } from '@/app/shared/http-service';
import { apiGlobalConstants } from '../config';

const URL = apiGlobalConstants.pacientes;
const URLADMIN = apiGlobalConstants.admin;
const URLAdmin = apiGlobalConstants.pacientes;

const fetchAllPacientes = (page = null, filtro = '') => {
  let filter = filtro.length > 0 ? filtro : "";

  if (page != null) {
    return httpClient.get(`${URL}?page=${page}&${filter}`).then(res => res.data)
  }
  else {
    return httpClient.get(`${URL}?${filter}`).then(res => res.data)
  }
};

 
const fetchOnePaciente = (adminId) => {
  return httpClient.get(`${URL}/${adminId}`).then(res => res.data);
};

const updatePacienteDatos = (adminToEdit) => {
  return httpClient.put(`${URL}/actualizar-datos-acceso/${adminToEdit.id}`, adminToEdit).then(res => res.data);
};
const updatePaciente = (adminToEdit) => {
  return httpClient.put(`${URL}/${adminToEdit.id}`, adminToEdit).then(res => res.data);
};

const createPaciente = (data) => {
  return httpClient.post(`${URL}`, data).then(res => res.data);
};

const deletePaciente = (adminId) => {
  return httpClient.delete(`${URL}/${adminId}`).then(res => res);
};

const suggestPacientes = (term = "", subido_consentimiento_informado) => {
  let filter
  if(subido_consentimiento_informado){
    filter = `subido_consentimiento_informado=1`; 
  }else{ 
    filter = `nombre=${term}`;
  }
  return httpClient.get(`${URL}/suggest?${filter}`).then(res => res.data);
};

const asociarFicheroPaciente = (PacienteToEdit) => {
  return httpClient.put(`${URLADMIN}/paciente/asociar-fichero/${PacienteToEdit.id}`, PacienteToEdit).then(res => res.data);
};
const subirFicheroPaciente = (data) => {
  return httpClient.post(`${URLADMIN}/paciente/subir-fichero/${data.id}`).then(res => res.data);
};
const ficherosPaciente = (id) => {
  return httpClient.get(`${URLADMIN}/paciente/ficheros/${id}`).then(res => res.data);
};
const tiposFicherosPaciente = () => {
  return httpClient.get(`${URLADMIN}/paciente/tipos-fichero`).then(res => res.data);
};
export {
  tiposFicherosPaciente,
  ficherosPaciente,
  subirFicheroPaciente,
  asociarFicheroPaciente,
  fetchAllPacientes,
  fetchOnePaciente,
  updatePaciente,
  deletePaciente,
  createPaciente, 
  updatePacienteDatos, 
  suggestPacientes
}
