<template>
  <div class="animated fadeIn">

    <CRow class="mb-2">
      <CCol sm="12">
        <QuillEditor
          id="nuevoComentario"
          name="nuevoComentario"
          ref="quillEditor"
          v-model:content="nuevoComentario.mensaje"
          contentType="html"
          :options="editorOption"
          class="quill-textarea custom-quill"
          />
      </CCol>

      <CCol sm="12" v-if="hayTexto()">
        <div class="d-flex justify-content-start mt-3">
          <CButton size="sm" :disabled="!hayTexto()" class="text-light" @click="comentar" color="primary">Enviar</CButton>
          <CButton size="sm"  @click="cancelar()" color="link" class="text-secondary">Cancelar</CButton>
        </div>
      </CCol>  
    </CRow>

  </div>
</template>

<script>
import { QUILL_CONFIG_COMENTARIO } from "@/config";
import { eliminarParrafosVacios } from '@/app/shared/utils/tools';

export default {
  name: 'CardNuevoComentario',
  props: {
    show: { type: Boolean, default: true, required: true },
  },
  // updated() {
  //   console.log(this.$refs);
  // },
  computed: {
    quill() {
      return this.mounted ? this.$refs.length > 0 ? this.$refs.quillEditor.content : '' : ''
    }
  },
  data() {
    return {
      editorOption: QUILL_CONFIG_COMENTARIO,
      nuevoComentario:{
        mensaje: "",
      },
      mounted: false,
    }
  },
  methods: {
    comentar() {

      let comentario = { ...this.nuevoComentario };
      comentario.mensaje = eliminarParrafosVacios(comentario.mensaje);

      this.$emit('comentar', comentario);
      this.$refs.quillEditor.setText("");
    },
    hayTexto() {
      return this.nuevoComentario.mensaje.replaceAll('<p>', '')
      .replaceAll('</p>', '')
      .replaceAll('<br>', '');
    },
    cancelar() {
      this.nuevoComentario.mensaje = ''
      this.$refs.quillEditor.setText("");
    },
  }
}
</script>

<style>

</style>
