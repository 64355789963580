import { h, resolveComponent } from 'vue'

const ListadoAdmins = () => import('@/app/views/configuracion/administradores/views/ListadoAdmins');
const DetalleAdmin = () => import('@/app/views/configuracion/administradores/views/DetalleAdmin');
const NuevoAdmin = () => import('@/app/views/configuracion/administradores/views/NuevoAdmin');
const MiCuenta = () => import('@/app/views/configuracion/administradores/views/MiCuenta');


const adminRoutes = [
  {
    path: 'usuarios',
    name: 'Usuarios',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      }
    },
    children: [
      {
        path: '',
        name: 'admins:Listado',
        component: ListadoAdmins,
        meta: {
          auth: true,
          entidad: 'administradores'
        }
      },
      {
        path: 'nuevo',
        name: 'admins:Nuevo',
        component: NuevoAdmin,
        meta: {
          auth: true,
          entidad: 'administradores'
        },
      },
      {
        path: 'yo',
        name: 'Mi cuenta',
        component: MiCuenta,
        meta: {
          auth: true,
          entidad: 'administradores'
        }
      },
      {
        path: ':id',
        name: 'admins:Detalle',
        component: DetalleAdmin,
        meta: {
          auth: true,
          entidad: 'administradores'
        },
      }
    ]
  }
];

export default adminRoutes;
