/*
 * Para organizar las consultas al api recurrentes y reducir errores 429 guardaremos en Vuex el estado actual de la consultas
 * Una vez creado el padre, se llamará a addStore ( $datosApi.commit('addStore') ) para lamacenar las primeras consultas.
 * En los componentes hijos comprobar si hay datosApi de la consulta usar o bien realizarla y almacenar.
 *
 * los campos a enviar son:
 * name: Nombre de la consulta (ej: sedes)
 * data: Donde guardaremos en data la respuesta de la consulta
 *
 * Estos campos serán consultados al cargar una vista
 * Se puede comprobar su funcionamiento en /EmpresasFilter y /ListadoEmpresas
*/

/*
 {
  name: 'sedes',
  data: [] // resp.data
  }
*/

export const datos = {
  state: {
    datosApi: {}
  },
  mutations: {
    addStore(state, payload) {
      state.datosApi[payload.name] = payload.data
    },
    removeStore(state, payload) {
      delete state.datosApi[payload.name]
    },
    removeStoreTipologia(state, payload) {
      let result = null;

      Object.entries(state.datosApi).forEach(([key, value]) => {
        // console.log(key, value)
        if (value?.tipo && value?.data?.item?.id == payload.id) {
          // console.error(value);
          result = value.data.item;
        }
      });
      
      delete state.datosApi[result?.clave];
    },
    editStore(state, payload) {
      state.datosApi[payload.name] = payload.data
    },
    removeAllData(state) {
      state.datosApi = {};
    },
  },
  actions: {
    addStore(context, payload) {
      context.commit('addStore', payload)
    },
    removeStore(context, payload) {
      context.commit('removeStore', payload)
    },
    removeStoreTipologia(context, payload) { // payload.id
      context.commit('removeStoreTipologia', payload)
    },
    editStore(context, payload) {
      context.commit('editStore', payload)
    },
    removeAllData(context) {
      context.commit('removeAllData')
    },
  },
}

/*
  this.$datosApi.commit('addStore', {
    name: 'sedes',
    data: [...res.data] || {...res.data}
  });
*/

/*

Tipologias:

tipologias, editar,eliminar identificar el store a partir del item.id
y  item.clave para eliminar el store de la tipologia

// data: {
  tipo: 'tipologia'
  item: { id, clave, ...}
  hijos:[]
}
*/