<template>

  <DeleteModal
    :show="modalDelete"
    :title="'Comentario'"
    :element="'el comentario seleccionado'"
    @cancelled="modalDelete=$event"
    @deleteItems="eliminarComentario"
  />

  <CCard class="mb-2">
    <CCardHeader
      class="pointer"
      @click="comentariosCardCollapsed = !comentariosCardCollapsed"
    >
      <div class="d-flex align-items-center justify-content-between">
        <slot name="header">
          <span class="title-card-list d-flex align-items-center">
            <CIcon name="cil-comment-bubble" size="lg" class="me-2" />
            <strong class="me-2">Comentarios ({{ items.length }})</strong>
          </span>
        </slot>
        <div class="card-header-actions">
          <CIcon v-if="!comentariosCardCollapsed" :name="`cil-chevron-top`" />
          <CIcon v-else :name="`cil-chevron-bottom`" />
        </div>
      </div>
    </CCardHeader>

    <spinner-ttt :show="loadingList"></spinner-ttt>
    <CCollapse :visible="!comentariosCardCollapsed" :duration="400">
      <CCardBody class="p-0 overflow-auto" style="max-height: 500px">
        <span ref="commentContainer" id="commentContainer"></span>
        <CListGroup v-if="items.length > 0" flush>
          <CListGroupItem
            v-for="(comentario, index) in items.slice(0, numComentsShow)"
            v-bind:key="comentario.id"
            :id="`comment-first-${index}`"
          >
            <CardComentarioItem 
              v-bind:item="comentario"
              v-bind:loading="loadingComment"
              @editar="(comentario) => editarComentario(comentario)"
              @eliminar="comentarioToEdit=$event, modalDelete = true"
              />

          </CListGroupItem>
        </CListGroup>
        <h6 v-else class="text-center my-4">No hay comentarios disponibles</h6>
        <CListGroup>
          <CCollapse
            :visible="!comentariosCollapsed"
            :duration="400"
            class="border-0 p-0 m-0 w-100"

          >
            <CListGroupItem
              v-for="(comentario, index) in items.slice(numComentsShow)"
              v-bind:key="comentario.id"
              width="100%"
              ref="comentarioList"
              :id="`coment-${index}`"
            >

            <CardComentarioItem 
              v-bind:item="comentario"
              v-bind:loading="loadingComment"
              @editar="(comentario) => editarComentario(comentario)"
              @eliminar="comentarioToEdit=$event, modalDelete = true"
              />

            </CListGroupItem>
          </CCollapse>
        </CListGroup>
        <span ref="scrollToMe" id="scrollToMe"></span>
      </CCardBody>
    </CCollapse>

    <CCardFooter v-if="!comentariosCardCollapsed && items.length > numComentsShow" class="white text-center pt-3 pb-2">
      <div v-if="items.length > numComentsShow && comentariosCollapsed">
        <a href="#" @click.prevent @click="toggleComentarios">Cargar más</a>
      </div>

      <div v-else-if="items.length > numComentsShow">
        <a href="#" @click.prevent @click="toggleComentarios">Cargar menos</a>
      </div>
    </CCardFooter>  

    <CCardFooter v-if="!comentariosCardCollapsed" class="new-comment">
      <div class="text-center">
        <CardNuevoComentario
          :show="nuevoModal"
          @comentar="(comentario) => crearComentario(comentario)"
        />
      </div>
    </CCardFooter>  
  </CCard>
  <toast ref="toast"></toast>
</template>

<script>

import { updateComentario, deleteComentario } from '@/app/shared/global/services/comentario-service';
import { eliminarParrafosVacios } from '@/app/shared/utils/tools';

import CardComentarioItem from '@/app/shared/components/cards/CardComentarioItem';
import CardNuevoComentario from '@/app/shared/components/cards/CardNuevoComentario';

import { ADMINSTRADORES_MODEL } from "@/config";

export default {
  name: 'CardComentarios',
  components: {   
    CardComentarioItem,
    CardNuevoComentario
  },
  props: {
    loading: { type: Boolean, default: true},
    comentarios: {type: Array, default() {return []}, required: true },
  },
  data() {
    return {
      comentariosCardCollapsed: true,
      comentariosCollapsed: true,
      nuevoModal: false,
      numComentsShow: 3,
      loadingComment: false,
      comentarioToEdit: null,
      modalDelete: false,
      timeout: null,
    }
  },
  emits: ['create', 'getComments', 'update:comentarios', 'update:loading'],
  beforeUnmount(){
    clearTimeout(this.timeout);
  },
  computed: {
    items: {
      get: function () {
        return this.comentarios
      },
      set: function (newValue) {
        this.$emit('update:comentarios', newValue)
      },
    },
    loadingList: {
      get: function () {
        return this.loading
      },
      set: function (newValue) {
        this.$emit('update:loading', newValue)
      },
    },
  },
  watch: {
    // comentariosCollapsed(newId, oldId){
    //   if (!newId) {
    //     const container = this.$refs.scrollToMe;
    //     this.$nextTick(() => {
    //       container.scrollIntoView({behavior: "smooth", block: "end"});
    //     });
    //   }
    // }
  },
  methods: {
    toggleComentarios() {
      this.comentariosCollapsed = !this.comentariosCollapsed
      this.scrollToElement()
    },

    scrollToElement() {
      // No se termina de desplegar al completo la lista de items con nextTick, esperar a que termine la  animación
      this.timeout = setTimeout(() => {
        // const container = this.$refs.scrollToMe; 
        // container.scrollIntoView({behavior: "smooth", block: "end"});

        if (this.comentariosCollapsed) {
          document.getElementById('comment-first-0').scrollIntoView({behavior: "smooth"});
        } else {
          document.getElementById('coment-0').scrollIntoView({behavior: "smooth"});
        }
      }, 401);
    },

    crearComentario(comentario){
      const container = this.$refs.commentContainer;
      container.scrollIntoView({behavior: "smooth"});

      this.$emit('create', {...comentario});
    },
    async editarComentario(comment){
    
      let comentario = { ...comment };
      comentario.mensaje = eliminarParrafosVacios(comentario.mensaje);

      try {
        const resp = await updateComentario(comentario)
        if (resp.status != 'success') throw resp.message

        this.$refs.toast.showToast(`Mensaje editado`, null, 'success', '2000');
        this.$emit('getComments', true);
      }
      catch (err) {
        this.$refs.toast.showToast(`Error al editar el comentario`, null, 'error', '5000');
      } finally {

      }
    },
    isAuthor(comentario) { 
      if (comentario) {
        return comentario.tipo_usuario === ADMINSTRADORES_MODEL.path &&
        localStorage.getItem("user_id") == comentario.usuario_id
      } 
      return false
    },
    async eliminarComentario(){

      try {

        const comentario =  {...this.comentarioToEdit};

        if (!this.isAuthor(comentario)) {
          this.$refs.toast.showToast(`Acción no permitida`, null, 'warn', '5000');
          return
        }

        const resp = await deleteComentario(comentario.id)
        if (resp.status != 'success') throw resp.message

        this.$refs.toast.showToast(`Mensaje eliminado`, null, 'success', '2000');
        this.$emit('getComments', true);
      }
      catch (err) {
        this.$refs.toast.showToast(`Error al eliminar el comentario`, null, 'error', '5000');
      } finally {
        this.comentarioToEdit = null;
        this.modalDelete = false;
      }
    },

    nuevoComentarioModal() {
      this.comentariosCardCollapsed = true
      this.nuevoModal = true
    },

    eliminarTodos() {
      this.comentarios.forEach((c) => {
        this.comentarios.splice(this.comentarios.indexOf(c))
      })

      this.$refs.toast.showToast(
        'Comentarios eliminados correctamente',
        null,
        'success',
        '5000',
      )
    },
  },
}
</script>

<style lang="scss" scoped>


.card-footer {

  &.white{
    background-color: #ffffff;
    border-top: 0.5px solid var(--cui-border-color-translucent);
  }

  &.new-comment {
    background-color: #ffffff;
    border-top: 2px solid var(--cui-border-color-translucent);
    padding-top: 2rem;
  }

  // padding: var(--cui-card-cap-padding-y) var(--cui-card-cap-padding-x);
  // color: var(--cui-card-cap-color);
  // background-color: var(--cui-card-cap-bg);
  // border-top: var(--cui-card-border-width) solid var(--cui-card-border-color);


}
</style>
